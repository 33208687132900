
import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL:
    'https://apis.fitconnector.com',
  headers: {
    authorization: '',
  },
});
